import { render, staticRenderFns } from "./ad_bosite.vue?vue&type=template&id=66a81046&scoped=true"
import script from "./ad_bosite.vue?vue&type=script&lang=js"
export * from "./ad_bosite.vue?vue&type=script&lang=js"
import style0 from "./ad_bosite.vue?vue&type=style&index=0&id=66a81046&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66a81046",
  null
  
)

export default component.exports