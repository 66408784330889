<template>
  <!-- 云纵 banner广告组件 -->
  <div class="adBox">
    <img
      v-if="imgurl"
      @click="btn_img"
      class="url_img"
      :src="imgurl"
      alt="广告位"
    />
  </div>
</template>
<script>
import axios from 'axios'
export default {
  name: 'ad_yunzong',
  props: ['urls'],
  data () {
    return {
      resTestData: {}, // 验证测试接口返回码
      imgurl: '',
      skipUrl: '',
      ingHeight: '',
      sburl: [],
      positionId: this.urls,
      accountId: 's14198000702441', // banner广告位账户ID
      secret: 'e5f0040797574d24', // banner广告位账户密钥
      spaceNumber: 'yuenad000003', // banner广告位编号

      requestId: '', // 随机请求ID
      bigMd5String: '',
      sign: '', // md5加密的sign
      dataAes: '', // aes加密后的data
      dataObj: {
        requestId: '',
        bidFloor: 0.0,
        plateNumber: 0.0,
        plateColor: null,
        vehicleColor: null,
        vehicleBrand: null,
        parkingDuration: 0.0,
        width: 0.0,
        height: 0.0,
        device: {
          ip: '192.168.0.130',
          ua: 'Mozilla/5.0 (iPhone; CPU iPhone OS 10_3_3 like Mac OS X) AppleWebKit/603.3.8(KHTML, like Gecko) Mobile / 14 G60 MicroMessenger / 7.0 .17(0x1700112a) NetType / 4 GLanguage / zh_CN '
        },
        site: null,
        user: null
      }
    }
  },
  methods: {
    btn_img () {
      console.log('准备跳转')
      console.log(this.sburl)
      for (let i = 0; i < this.sburl.length; i++) {
        axios({
          url: this.sburl[i],
          method: 'GET',
          header: {
            'content-type': 'application/json' // 默认值
          }
        }).then((res) => {})
      }
      window.location.replace(this.landing)
    },
    async bannerInfo () {
      const adUrl =
        'https://bojuliang.yuntingiot.com/api/getAc/501008286821191680'
      const res = await axios({
        url: adUrl,
        method: 'GET',
        header: {
          'content-type': 'application/json' // 默认值
        }
      })
      if (res.data.code === 200) {
        this.imgurl = res.data.data.creativeImg.creativeUrl
        this.sburl = res.data.data.trackUrl
        this.landing = res.data.data.landingPage
      } else {
      }
    },
    async acBannerTrackUrl () {
      console.log('this.sburl', this.sburl)
      for (let i = 0; i < this.sburl.length; i++) {
        axios({
          url: this.sburl,
          method: 'GET',
          header: {
            'content-type': 'application/json' // 默认值
          }
        }).catch(console.log)
      }
    },
    async get_banner () {
      try {
        await this.bannerInfo()
        await this.acBannerTrackUrl()
      } catch (e) {
        console.log(e)
      }
    }
  },
  mounted () {
    console.log('我要展示yunzong广告')
    this.get_banner()
  },
  created () {
    // console.log(this.positionId);
  }
}
</script>

  <style scoped>
.adBox {
  width: 100%;
  height: 100%;
  /* display: flex;
          align-items: center;
          justify-content: center; */
}

.url_a {
  width: 100%;
  height: 100%;
}

.url_img {
  width: 100%;
  height: 100%;
}
</style>
