<template>
  <!-- 伯斯特banner广告组件 -->
  <div class="adBox">
    <img
      v-if="imgurl"
      @click="btn_img"
      class="url_img"
      :src="imgurl"
      alt="广告位"
    />
  </div>
</template>
<script>
import axios from 'axios'
import CryptoJS from 'crypto-js'
import md5 from 'js-md5'
// import * as util from '../../../config/envsetting'
export default {
  name: 'ad_bosite',
  props: ['urls'],
  data () {
    return {
      resTestData: {}, // 验证测试接口返回码
      imgurl: '',
      skipUrl: '',
      ingHeight: '',
      sburl: [],
      positionId: this.urls,
      accountId: 's14198000702441', // banner广告位账户ID
      secret: 'e5f0040797574d24', // banner广告位账户密钥
      spaceNumber: 'yuenad000003', // banner广告位编号

      requestId: '', // 随机请求ID
      bigMd5String: '',
      sign: '', // md5加密的sign
      dataAes: '', // aes加密后的data
      dataObj: {
        requestId: '',
        bidFloor: 0.0,
        plateNumber: 0.0,
        plateColor: null,
        vehicleColor: null,
        vehicleBrand: null,
        parkingDuration: 0.0,
        width: 0.0,
        height: 0.0,
        device: {
          ip: '192.168.0.130',
          ua: 'Mozilla/5.0 (iPhone; CPU iPhone OS 10_3_3 like Mac OS X) AppleWebKit/603.3.8(KHTML, like Gecko) Mobile / 14 G60 MicroMessenger / 7.0 .17(0x1700112a) NetType / 4 GLanguage / zh_CN '
        },
        site: null,
        user: null
      }
    }
  },
  methods: {
    // 随机生成请求ID
    randomCount () {
      var data = [
        '0',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        'a',
        'b',
        'c',
        'd',
        'e',
        'f'
      ]
      for (var j = 0; j < 500; j++) {
        var result = ''
        for (var i = 0; i < 20; i++) {
          var r = Math.floor(Math.random() * 16)
          result += data[r]
        }
      }
      this.requestId = 'wx' + result
      // 开始data加密
      this.countAesData()
    },
    // 计算md5加密
    countMd5 (params) {
      const md5String = md5(params)
      const bigScrect = md5String.toUpperCase()
      this.bigMd5String = bigScrect
      return bigScrect
    },
    // 加密data数据
    countAesData () {
      this.dataObj.requestId = this.requestId

      var data = this.dataObj
      var key = CryptoJS.enc.Latin1.parse('e5f0040797574d24')
      var iv = CryptoJS.enc.Latin1.parse('e5f0040797574d24')
      // 加密
      var encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.ZeroPadding
      })
      this.dataAes = encrypted.toString()
      console.log('----', this.dataAes)
      // 拼接字符串
      const toMd5String =
        this.requestId +
        '&' +
        this.spaceNumber +
        '&' +
        this.secret +
        '&' +
        encrypted.toString()

      this.sign = this.countMd5(toMd5String)
      // banner广告
      this.getBannerAD()
    },

    // 获取banner广告数据
    async getBannerAD () {
      const params = new URLSearchParams()
      params.append('accountId', this.accountId)
      params.append('requestId', this.requestId)
      params.append('data', this.dataAes)
      params.append('sign', this.sign)

      const res = await axios({
        url: 'https://apitest.parkingad.cn/api/ad/bid',
        method: 'POST',
        header: {
          'content-type': 'application/x-www-form-urlencoded'
        },
        data: params
      })
      console.log('获取广告数据返回结果', res)

      if (res.status !== 200 || !res.data.isSuccess || res.data.data.bidSeat == null) {
        console.log('广告数据获取异常')
      } else {
        const resData = res.data.data.bidSeat
        // 跳转地址
        this.skipUrl = resData.target
        // 广告图片地址
        this.imgurl = resData.materias[0].url
        // 广告图片宽度
        this.imgWidth = resData.materias[0].width
        // 广告图片高度
        this.imgHeight = resData.materias[0].height
      }
    },
    // 点击广告跳转
    btn_img () {
      // new 跳转
      console.log('准备跳转')
      window.location.href = this.skipUrl
    }
  },
  mounted () {
    console.log('我要展示伯斯特广告')
    this.randomCount()
  },
  created () {}
}
</script>

  <style scoped>
.adBox {
  width: 100%;
  height: 100%;
  /* display: flex;
          align-items: center;
          justify-content: center; */
}

.url_a {
  width: 100%;
  height: 100%;
}

.url_img {
  width: 100%;
  height: 100%;
}
</style>
