<template>
  <!-- banner广告组件 -->
  <div class="adBox">
    <ad-yunzong v-if="adFlag === '1'" :urls="urls"> </ad-yunzong>
    <ad-bosite v-if="adFlag === '2'"> </ad-bosite>

  </div>
</template>
<script>
import * as util from '../../config/envsetting'
import AdBosite from './ads/ad_bosite.vue'
import AdYunzong from './ads/ad_yunzong.vue'
export default {
  name: 'advertising',
  components: { AdYunzong, AdBosite },
  props: ['urls'],
  data () {
    return {
      adFlag: ''
    }
  },
  methods: {},
  mounted () {
    console.log('广告来源', util.adFlag)
    this.adFlag = util.adFlag
  },
  created () {}
}
</script>

<style scoped>
.adBox {
  width: 100%;
  height: 100%;
}

.url_a {
  width: 100%;
  height: 100%;
}

.url_img {
  width: 100%;
  height: 100%;
}
</style>
